import React, { useContext, useState, useEffect } from 'react';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import { Account } from '../../../helpers/GlobalInterfaces';
import AuthService from '../../../helpers/AuthService';
import {
	administratorsMenu,
	configurationsMenu,
	dashboardMenu,
	investmentsMenu,
	savingsMenu,
} from '../../../menu';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [userData, setUserData] = useState<Account | null>();

	const userDataRaw = AuthService().getUserData();

	useEffect(() => {
		if (userDataRaw != null) {
			setUserData(JSON.parse(userDataRaw));
		}
	}, [userDataRaw]);

	return (
		<Aside>
			{userData && userData.id > 0 && (
				<>
					<AsideHead>
						<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
					</AsideHead>
					<AsideBody>
						<Navigation menu={dashboardMenu} id='aside-dashboard' />
						<NavigationLine />
						<Navigation menu={configurationsMenu} id='aside-config' />
						<NavigationLine />
						<Navigation menu={savingsMenu} id='aside-savings' />
						<NavigationLine />
						<Navigation menu={investmentsMenu} id='aside-investments' />
						<NavigationLine />
						<Navigation menu={administratorsMenu} id='aside-administrations' />
						<NavigationLine />
					</AsideBody>
					<AsideFoot>
						<User />
					</AsideFoot>
				</>
			)}
		</Aside>
	);
};

export default DefaultAside;
