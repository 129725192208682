import React from 'react';
import { RouteProps } from 'react-router-dom';
import { authMenu } from '../menu';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';
import AuthService from '../helpers/AuthService';

const isUserLoggedIn = AuthService().isUserLogged();

const asides: RouteProps[] = [
	{ path: authMenu.login.path, element: null },
	{ path: authMenu.confirmLogin.path, element: null },
	{ path: authMenu.forgetGroup.subMenu.forgetPassword.path, element: null },
	{ path: authMenu.forgetGroup.subMenu.confirmation.path, element: null },
	{ path: authMenu.forgetGroup.subMenu.resetPassword.path, element: null },
	{ path: '*', element: isUserLoggedIn ? <DefaultAside /> : null },
];

export default asides;
