import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import AuthService from '../../../helpers/AuthService';
import { Account } from '../../../helpers/GlobalInterfaces';
import CommonHeaderRight from './CommonHeaderRight';
import Avatar from '../../../components/Avatar';
import MaleAvatar from '../../../assets/img/avatar/male.jpg';
import FemaleAvatar from '../../../assets/img/avatar/female.jpg';
import { administratorsMenu } from '../../../menu';

const DashboardHeader = () => {
	const [userData, setUserData] = useState<Account>();
	const isUserLogged = AuthService().isUserLogged();

	const { darkModeStatus } = useDarkMode();

	useEffect(() => {
		if (isUserLogged) {
			const data = AuthService().getUserData();
			setUserData(JSON.parse(data!));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const navigate = useNavigate();

	return (
		<Header>
			<HeaderLeft>
				<div className='d-flex align-items-center'>
					<div className='row g-4'>
						<div className='col-md-auto'>
							<div
								className={classNames('fs-4', 'text-uppercase', {
									'text-dark': !darkModeStatus,
								})}>
								HiYield Mobile
							</div>
							<div className='fs-6'>CRM TOOL</div>
						</div>
					</div>
				</div>
			</HeaderLeft>
			<HeaderRight>
				<CommonHeaderRight
					userData={userData!}
					afterChildren={
						<div className='col d-flex align-items-center'>
							{userData && userData.id > 0 && (
								<Dropdown className='d-inline-block' setIsOpen={() => {}}>
									<DropdownToggle hasIcon={false}>
										<div className='col d-flex align-items-center'>
											<div className='me-3'>
												{userData.id && userData.passportPhoto ? (
													<Avatar
														src={`data:image/png;base64,${userData.passportPhoto}`}
														size={48}
														color='primary'
													/>
												) : (
													<Avatar
														src={
															userData.gender &&
															userData.gender.toLowerCase() ===
																'female'
																? FemaleAvatar
																: MaleAvatar
														}
														size={48}
														color='primary'
													/>
												)}
											</div>
											<div>
												<div className='fw-bold fs-6 mb-0'>
													{`${userData.firstname.toUpperCase()} ${userData.lastname.toUpperCase()}`}
												</div>
												<div className='text-muted'>
													<small>
														{userData.roles[0].name.toUpperCase()}
													</small>
												</div>
											</div>
										</div>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button
												icon={administratorsMenu.profile.icon}
												tag='a'
												onClick={() =>
													navigate(administratorsMenu.profile.path)
												}>
												Profile
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												icon='PowerSettingsNew'
												tag='a'
												onClick={() => AuthService().logoutUser()}>
												Logout
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							)}
						</div>
					}
				/>
			</HeaderRight>
		</Header>
	);
};

export default DashboardHeader;
