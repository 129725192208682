// eslint-disable-next-line import/no-extraneous-dependencies
import forge from 'node-forge';

export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	// @ts-ignore
	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	// @ts-ignore
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs: any[]) => {
	return (value: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price: number) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array: any[]) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1: number, value2: number) =>
	Number(((value1 / value2 - 1) * 100).toFixed(2));

export const getFirstLetter = (text: string, letterCount = 2): string =>
	// @ts-ignore
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func: (arg0: any) => void, wait = 1000) => {
	let timeout: string | number | NodeJS.Timeout | undefined;

	return function executedFunction(...args: any[]) {
		const later = () => {
			clearTimeout(timeout);
			// @ts-ignore
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const encryptString = (value: string) => {
	const publicKeyPem = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAz6QmuxWdW/Ibps9iKMk9
FZy2gVhTTllgVdSyka7zzcIk6l++sV8PRh26eiYQWa/d5vz5eVe8i4X2KGDw5DWt
lpg/9HfBg0EBUV6saJGxHSbJcyB84ylP0XRbViDB1ZhrGQUJim5LQawb36wW54Gv
C4806kLLCx8T9zeeJ2Enk0xGcLQuLdrzj2bwuSvcfSH3EQNFH32a1Y+dWoiS6wDg
wKpmm+GtP+dkKbiuc5WdCwYh9A5MoioYBBdIbumXOnbFr7+3ofRNPIw1hjCwQR80
KnSRVmnjvgL0UFODCwQYB5X2SESIsTpqd22FmIdkIOMwRL9lJ5/WB0PcXe9hLft4
LwIDAQAB
-----END PUBLIC KEY-----`;
	const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
	const encryptedData = publicKey.encrypt(value, 'RSA-OAEP', {
		md: forge.md.sha256.create(), // Specify hashing algorithm
		mgf1: {
			md: forge.md.sha1.create(), // Masking function (default is SHA-1)
		},
	});

	// Base64-encode the encrypted data for transmission
	return forge.util.encode64(encryptedData);
};

export const encryptRequest = (value: string) => {
	// Generate a random AES key (256-bit for AES-GCM)
	const aesKey = forge.random.getBytesSync(32);

	// Generate a random nonce for AES-GCM
	const nonce = forge.random.getBytesSync(12); // 96-bit nonce for AES-GCM

	// Encrypt the value using AES-GCM
	const cipher = forge.cipher.createCipher('AES-GCM', aesKey);
	cipher.start({ iv: nonce }); // 'iv' is synonymous with 'nonce' in forge
	cipher.update(forge.util.createBuffer(value, 'utf8'));
	cipher.finish();
	const encrypted = cipher.output.getBytes();
	const tag = cipher.mode.tag.getBytes(); // Authentication tag

	// Now, encrypt the AES key using RSA-OAEP
	const publicKeyPem = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAz6QmuxWdW/Ibps9iKMk9
FZy2gVhTTllgVdSyka7zzcIk6l++sV8PRh26eiYQWa/d5vz5eVe8i4X2KGDw5DWt
lpg/9HfBg0EBUV6saJGxHSbJcyB84ylP0XRbViDB1ZhrGQUJim5LQawb36wW54Gv
C4806kLLCx8T9zeeJ2Enk0xGcLQuLdrzj2bwuSvcfSH3EQNFH32a1Y+dWoiS6wDg
wKpmm+GtP+dkKbiuc5WdCwYh9A5MoioYBBdIbumXOnbFr7+3ofRNPIw1hjCwQR80
KnSRVmnjvgL0UFODCwQYB5X2SESIsTpqd22FmIdkIOMwRL9lJ5/WB0PcXe9hLft4
LwIDAQAB
-----END PUBLIC KEY-----`;

	const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
	const encryptedAESKey = publicKey.encrypt(aesKey, 'RSA-OAEP', {
		md: forge.md.sha256.create(),
		mgf1: {
			md: forge.md.sha1.create(),
		},
	});

	// Base64-encode the encrypted AES key, nonce, and tag for transmission
	const encodedAESKey = forge.util.encode64(encryptedAESKey);
	const encodedNonce = forge.util.encode64(nonce); // Renamed to nonce
	const encodedEncryptedData = forge.util.encode64(encrypted);
	const encodedTag = forge.util.encode64(tag);

	// Send the encrypted AES key, nonce, tag, and encrypted data
	return {
		aesKey: encodedAESKey,
		nonce: encodedNonce, // Using nonce instead of IV
		encrypted_data: encodedEncryptedData,
		tag: encodedTag,
	};
};
