import React from 'react';
import { RouteProps } from 'react-router-dom';
import { authMenu } from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import AuthService from '../helpers/AuthService';

const isUserLoggedIn = AuthService().isUserLogged();

const headers: RouteProps[] = [
	{ path: authMenu.login.path, element: null },
	{ path: authMenu.confirmLogin.path, element: null },
	{ path: authMenu.forgetGroup.subMenu.forgetPassword.path, element: null },
	{ path: authMenu.forgetGroup.subMenu.confirmation.path, element: null },
	{ path: authMenu.forgetGroup.subMenu.resetPassword.path, element: null },
	{
		path: `*`,
		element: isUserLoggedIn ? <DashboardHeader /> : null,
	},
];

export default headers;
