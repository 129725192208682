export const links = {
	bearerUrl: 'https://api.hiyield.co/api/v1',
	galleryUrl: 'https://api.hiyield.co/v1/assets',
	pages: {
		// Auth URLs
		login: 'login',
		loginVerify: 'loginVerify',
		createToken: 'create-verification-token',
		checkToken: 'check-verification-token',
		verifyToken: 'verify-verification-token',
		forgotPassword: 'forgot-password',
		resetPassword: 'reset-password',
		logout: 'profile/logout',
		// Admin URLs
		admin: 'staffs',
		adminSimple: 'staffs/load/simple',
		// API Key URLs
		apiKeys: 'api-keys',
		// Backend Error URLs
		backendErrors: 'backend-errors',
		// Configuration URLs
		configurations: 'configurations',
		// Currencies URLs
		currencies: 'currencies',
		currenciesSimple: 'currencies/simple',
		// Dashboard
		dashboard: 'dashboard',
		// Devices
		devices: 'devices',
		// FAQs URLs
		faqs: 'faqs',
		// FAQ Groups URLs
		faqGroups: 'faq-groups',
		// Fixed Deposit URLs
		fixedDeposits: 'fixed-deposits',
		// Fixed Deposit URLs
		fixedDepositTenures: 'fixed-deposit-tenures',
		// Frequency URLs
		frequencies: 'frequencies',
		// Identity Type URLs
		identityTypes: 'identity-types',
		// Investments URLs
		investments: 'investments',
		// Investment Package URLs
		investmentPackages: 'investment-packages',
		// Kyc Level URLs
		kycLevels: 'kyc-levels',
		// Kyc requests URLs
		kycRequests: 'kyc-requests',
		// Log URLs
		logs: 'logs',
		// Messages URLs
		sendMessage: 'messages/send-message',
		loadMessage: 'messages/load-messages',
		loadSingleMessage: 'messages/load-single-message',
		closeMessage: 'messages/close-message',
		// Profile URLs
		uploadPassport: 'profile/upload-passport',
		changePassword: 'profile/change-password',
		createPin: 'profile/create-pin',
		changePin: 'profile/change-pin',
		profile: 'profile',
		notifications: 'profile/notifications',
		// Proof of Address Type URLs
		proofOfAddressTypes: 'proof-of-address-types',
		// Role URLs
		roles: 'roles',
		roleSimple: 'roles/load/simple',
		permissions: 'permissions',
		// Stash URLs
		stashes: 'stashes',
		deactivateStash: 'stashes/deactivate-stash',
		disableStashAutosave: 'stashes/disable-stash-autosave',
		// Target Package URLs
		targetPackages: 'target-packages',
		// Target URLs
		targets: 'targets',
		deactivateTarget: 'targets/deactivate-target',
		disableTargetAutosave: 'targets/disable-target-autosave',
		// Text Config URLs
		textConfigs: 'text-configs',
		// Transaction URLs
		transactions: 'transactions',
		// User URLs
		users: 'users',
		payCommission: 'users/pay_commission',
		// Wallet URLs
		wallets: 'wallets',
		creditWallet: 'wallets/credit',
		debitWallet: 'wallets/debit',
		lockAmount: 'wallets/lock-amount',
		unlockAmount: 'wallets/unlock-amount',
		postNoDebitStatus: 'wallets/post-no-debit-status',
	},
};

export function getLink(link: string) {
	return `${links.bearerUrl}/admin/${link}`;
}
