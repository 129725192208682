import swal from 'sweetalert';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getLink, links } from './urls';
import { Response } from './GlobalInterfaces';
import { encryptRequest } from './helpers';
// eslint-disable-next-line import/no-cycle

const AuthService = () => {
	const isLoggedIn = 'ptl_developed_hiyield_app_admin_is_logged';
	const accessToken = 'ptl_developed_hiyield_app_admin_accessToken';
	const userData = 'ptl_developed_hiyield_app_admin_other_data';

	function logUserIn() {
		localStorage.setItem(isLoggedIn, 'true');
	}

	function isUserLogged() {
		const prefs = localStorage.getItem(isLoggedIn);
		return prefs === 'true';
	}

	function signOut() {
		localStorage.clear();
	}

	function setAccessToken(value: string) {
		localStorage.setItem(accessToken, value);
	}

	function getAccessToken() {
		return localStorage.getItem(accessToken);
	}

	function setUserData(value: string) {
		localStorage.setItem(userData, value);
	}

	function getUserData() {
		return localStorage.getItem(userData);
	}

	async function getRequest(link: string, load = true): Promise<Response> {
		if (load) {
			NProgress.start();
		}
		const response = await fetch(link, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(accessToken)}`,
			},
		}).then((data) => data.json());
		if (load) {
			NProgress.done();
		}
		if (
			response.code === 401 ||
			response.message.toLowerCase() === 'unauthorized' ||
			response.message.toLowerCase() === 'unauthenticated.'
		) {
			signOut();
			window.location.href = '/login';
		}
		return response;
	}

	async function deleteRequest(link: string, load = true): Promise<Response> {
		if (load) {
			NProgress.start();
		}
		const response = await fetch(link, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(accessToken)}`,
			},
		}).then((data) => data.json());
		if (load) {
			NProgress.done();
		}
		if (
			response.code === 401 ||
			response.message.toLowerCase() === 'unauthorized' ||
			response.message.toLowerCase() === 'unauthenticated.'
		) {
			signOut();
			window.location.href = '/login';
		}
		return response;
	}

	async function putRequest(link: string, params?: any) {
		NProgress.start();
		const response = await fetch(link, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(accessToken)}`,
			},
			body: JSON.stringify(encryptRequest(JSON.stringify(params ?? {}))),
		}).then((data) => data.json());
		NProgress.done();
		if (
			response.code === 401 ||
			response.message.toLowerCase() === 'unauthorized' ||
			response.message.toLowerCase() === 'unauthenticated.'
		) {
			signOut();
			window.location.href = '/login';
		}
		return response;
	}

	async function patchRequest(link: string, params?: any) {
		NProgress.start();
		const response = await fetch(link, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(accessToken)}`,
			},
			body: JSON.stringify(encryptRequest(JSON.stringify(params ?? {}))),
		}).then((data) => data.json());
		NProgress.done();
		if (
			response.code === 401 ||
			response.message.toLowerCase() === 'unauthorized' ||
			response.message.toLowerCase() === 'unauthenticated.'
		) {
			signOut();
			window.location.href = '/login';
		}
		return response;
	}

	async function postRequest(link: string, params: any) {
		NProgress.start();
		const response = await fetch(link, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(accessToken)}`,
			},
			body: JSON.stringify(encryptRequest(JSON.stringify(params ?? {}))),
		}).then((data) => data.json());
		NProgress.done();
		if (
			response.code === 401 ||
			response.message.toLowerCase() === 'unauthorized' ||
			response.message.toLowerCase() === 'unauthenticated.'
		) {
			signOut();
			window.location.href = '/login';
		}
		return response;
	}

	// Implement other request methods similarly

	async function logoutNow(): Promise<Response> {
		NProgress.start();
		const response = await fetch(getLink(links.pages.logout), {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(accessToken)}`,
			},
		}).then((data) => data.json());
		NProgress.done();
		if (
			response.status ||
			(response.message &&
				(response.message.toLowerCase() === 'unauthorized' ||
					response.message.toLowerCase() === 'unauthenticated.'))
		) {
			signOut();
			window.location.href = '/login';
		}
		return response;
	}

	function logoutUser() {
		swal('Are you sure you want to sign out?', {
			buttons: {
				cancel: {
					text: 'Stay logged In!',
					value: 'cancel',
				},
				catch: {
					text: 'Yes, I am!',
					value: 'catch',
				},
			},
		}).then(async (value: any) => {
			if (value === 'catch') {
				localStorage.clear();
				window.location.href = '/login';
			}
		});
	}

	async function getUserDataBg(reload: boolean) {
		const response = await getRequest(getLink(links.pages.profile), false);
		if (response.status) {
			setUserData(JSON.stringify(response.data.admin));
			if (reload) {
				window.location.reload();
			}
		} else if (
			(response.message && response.message.toLowerCase() === 'unauthorized') ||
			response.message.toLowerCase() === 'unauthenticated'
		) {
			signOut();
		}
	}

	return {
		isUserLogged,
		logUserIn,
		signOut,
		setAccessToken,
		getAccessToken,
		setUserData,
		getUserData,
		logoutUser,
		getRequest,
		deleteRequest,
		putRequest,
		patchRequest,
		postRequest,
		logoutNow,
		getUserDataBg,
	};
};

export default AuthService;
